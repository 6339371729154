import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useTranslation } from 'react-i18next'

import SEO from "@components/Seo"
import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
// import Header from "@components/blocks/global/Header"
import PageHeader from "@components/blocks/common/PageHeader"
import PageSectionTitle from "@components/blocks/common/PageSectionTitle"
// import OfficialMaterial from "@components/blocks/gallery/OfficialMaterial"
import OfficalWorks from "@components/blocks/index/OfficialWorks"

import ContentListV2 from "@components/ContentList-v2"

import css from "@css/components/blocks/common/Page.module.styl"

export default function Illust({data})
{
    const {t} = useTranslation()
	const { allWordpressWpIllust, allWordpressWpComics, allWordpressWpMovie } = data
	const illustData = allWordpressWpIllust.edges
	const comicsData = allWordpressWpComics.edges
	const movieData = allWordpressWpMovie.edges
	const [collaboList, setCollaboList] = useState()
	const [bnsList, setBnsList] = useState()
	const [kvList, setKvList] = useState()

	useEffect(()=>{
		let kvList = []
		let collaboList = []
		let bnsList = []
		console.log('test',illustData)
		illustData.map((v,i)=>{
			const { type } = v.node.acf
			if( type === "3" ){
				kvList.push(v)
			} else
			if( type === "2" ){
				collaboList.push(v)
			} else {
				bnsList.push(v)
			}
		})
		setCollaboList(collaboList)
		setBnsList(bnsList)
		setKvList(kvList)
	},[])

	return (
		<React.Fragment>
			<SEO title={'ILLUST'} />
			<main className={css.container} id="main">
				<PageHeader l1="ILLUST" l2={t('illust__headerTitle2')}/>
				<div className={css.content}>
					{/* <div className={css.topText}>
						{t('illust__headerSubTitle1')}
					</div> */}
					<div className={css.illust}>
						{ kvList && <section className={css.section}>
							<PageSectionTitle l1={t('illust__sectionTitle0-1')} l2={t('illust__sectionTitle0-2')}/>
							<ContentListV2 type="kv_illust" data={kvList} isThumbnailTitle={false}/> 
						</section>}
						{ collaboList && <section className={css.section}>
							<PageSectionTitle l1={t('illust__sectionTitle1-1')} l2={t('illust__sectionTitle1-2')}/>
							<ContentListV2 type="collabo_illust" data={collaboList} isThumbnailTitle={false} isIllustDetail/>
						</section>}
						{ bnsList && <section className={css.section}>
							<PageSectionTitle l1={t('illust__sectionTitle2-1')} l2={t('illust__sectionTitle2-2')}/>
							<ContentListV2 type="bns_illust" data={bnsList} isThumbnailTitle={false}/> 
						</section>}
						<section className={css.section}>
							<PageSectionTitle l1={t('index__supportTitleOfficialWorks')} l2={t('index__officialWorksTitle2')}/>
							<OfficalWorks contentMode />
						</section>
					</div>
				</div>
			</main>
			<FooterSns/>
			<Footer/>
		</React.Fragment>
	)
}

export const pageQuery = graphql`
    query {
        allWordpressWpIllust (sort: { fields: date, order: DESC }){
            edges {
            	node {
					acf {
						type
						artist_name
						artist_url
						artist_url_label
					}
            		id
            		slug
            		title
            		content
            		date(formatString: "MMMM DD, YYYY")
            		featured_media {
            			source_url
                        localFile {
                            publicURL
                            childImageSharp {
                                high: fluid(maxWidth: 1440) {
                                    ...GatsbyImageSharpFluid
								}
								middle: fluid(maxWidth: 720) {
                                    ...GatsbyImageSharpFluid
                                }
                                thumbnail: fluid(maxWidth: 640, cropFocus: CENTER, fit: COVER) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
            		}
            	}
            }
        }
        allWordpressWpMovie (sort: { fields: date, order: DESC }) {
            edges {
            	node {
            		id
            		slug
            		title
            		content
            		date(formatString: "MMMM DD, YYYY")
            		featured_media {
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 1280) {
                                    ...GatsbyImageSharpFluid
                                }
                                thumbnail: fluid(maxWidth: 640) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
            		}
                    acf {
                        movie_youtube_id
                    }
            	}
            }
        }
        allWordpressWpComics (sort: { fields: date, order: ASC }) {
            edges {
            	node {
            		id
            		slug
            		title
            		content
					date(formatString: "MMMM DD, YYYY")
					path
            		featured_media {
            			source_url
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 1280) {
                                    ...GatsbyImageSharpFluid
                                }
                                thumbnail: fluid(maxWidth: 640) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
            		}
                    acf {
                        comics_url
                    }
            	}
            }
        }
    }
`